<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >
        <h6
          class="text-info"
        >
          <feather-icon icon="AlertCircleIcon" />
          <span class="align-middle ml-25">Datos Generales</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Buscar Empresa"
          label-for="nroDoc"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search-nro-doc"
              v-model="formData.documento"
              style="padding: 0.438rem 1rem;"
              :placeholder="placeholderTP"
              type="number"
            />
            <b-button
              variant="info"
              @click="searchData"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group>
        </b-form-group>

        <div
          v-if="isBusy"
          class="text-center text-primary my-2"
        >
          <b-spinner
            variant="primary"
            class="align-middle"
          />
          <strong class="ml-1">Cargando...</strong>
        </div>

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Razón Social"
              label-for="razonSocial"
            >
              <b-form-input
                id="razonSocial"
                v-model="formData.razonSocial"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <h6
          class="text-info"
        >
          <feather-icon icon="MapPinIcon" />
          <span class="align-middle ml-25">Ubicación</span>
        </h6>

        <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

        <b-form-group
          label="Seleccione Departamento"
          label-for="dpto"
        >
          <v-select
            v-model="dptoSel"
            :reduce="m => m.idUbigeo"
            label="descripcion"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dptos"
            @input="getUbigeos('prov', dptoSel.slice(0, 2))"
          />
        </b-form-group>
        <b-form-group
          label="Seleccione Provincia"
          label-for="prov"
        >
          <v-select
            v-model="provSel"
            :reduce="m => m.idUbigeo"
            label="descripcion"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="provs"
            @input="getUbigeos('dist', provSel.slice(0, 4))"
          />
        </b-form-group>
        <b-form-group
          label="Seleccione Distrito"
          label-for="dist"
        >
          <v-select
            v-model="distSel"
            :reduce="m => m.idUbigeo"
            label="descripcion"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dists"
          />
        </b-form-group>

        <b-form-group
          label="Dirección"
          label-for="direccion"
        >
          <b-form-input
            id="direccion"
            v-model="formData.direccion"
          />
        </b-form-group>

        <h6
          class="text-info"
        >
          <feather-icon icon="ImageIcon" />
          <span class="align-middle ml-25">Multimedia</span>
        </h6>

        <!-- Logo -->
        <b-form-group
          label="Logo (500x500)"
          label-for="logo"
        >
          <b-row class="container">
            <b-col md="4">
              <b-media class="mb-2 text-center">
                <template #aside>
                  <b-avatar
                    ref="previewElLogo"
                    :src="imgBase64Logo"
                    size="100px"
                    rounded=""
                  />
                </template>
              </b-media>
            </b-col>
            <b-col md="8">
              <b-row class="mt-1">
                <b-col md="12">
                  <b-button
                    variant="info"
                    class="btn-block btn-sm"
                    @click="$refs.refInputElLogo.$el.click()"
                  >
                    <b-form-file
                      id="file"
                      ref="refInputElLogo"
                      v-model="fileLogo"
                      accept=".jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="uploadLogo"
                    />
                    <span class="d-none d-sm-inline">Seleccionar Logo</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row style="padding-top:10px">
                <b-col md="12">
                  <b-button
                    variant="light"
                    class="btn-block btn-sm"
                    @click="onDeleteLogo"
                  >
                    <span class="d-none d-sm-inline">Eliminar Logo</span>
                    <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow, BCol, BAvatar, BMedia, BSidebar, BForm, BFormGroup, BFormInput, BInputGroup, BButton, BSpinner, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
// import moment from 'moment'
import config from '@/services/config'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BMedia,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    vSelect,
    BSpinner,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const { baseURL } = config
    const pathMultimedia = ref(`${baseURL}api-erpunigas/api/v1/multimedia/`)
    const titleForm = ref('')
    const isBusy = ref(false)
    const formData = ref({})
    const fotoReniec = ref('')
    const dptoSel = ref('220000')
    const provSel = ref(null)
    const distSel = ref(null)
    const dptos = ref([])
    const provs = ref([])
    const dists = ref([])
    const placeholderTP = ref('Ingrese RUC')
    const imgBase64Logo = ref(null)
    const refInputElLogo = ref(null)
    const fileLogo = ref(null)
    const previewElLogo = ref(null)

    const resetForm = () => {
      formData.value = {}
      fotoReniec.value = ''
    }

    const onDeleteLogo = () => {
      imgBase64Logo.value = null
    }

    const uploadLogo = e => {
      const fileTemp = e.target.files[0]
      fileLogo.value = fileTemp
      imgBase64Logo.value = URL.createObjectURL(fileLogo.value)
    }

    const getUbigeos = async (type, codUbigeo) => {
      await store
        .dispatch('catalogo/UBIGEO_FIND_ALL', {
          get: type,
          codUbigeo,
        })
        .then(response => {
          if (type === 'dpto') {
            dptos.value = response
            provSel.value = null
            distSel.value = null
            provs.value = []
            dists.value = []
          }
          if (type === 'prov') {
            provs.value = response
            provSel.value = null
            distSel.value = null
            dists.value = []
          }
          if (type === 'dist') {
            dists.value = response
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const searchData = async () => {
      isBusy.value = true
      placeholderTP.value = 'Ingrese RUC'
      await store
        .dispatch('helpers/GET_DATOS_BY_RUC', {
          dni: formData.value.documento,
        })
        .then(async response => {
          console.log(response)
          isBusy.value = false
          formData.value.representanteLegal = response.nombre
          formData.value.razonSocial = response.nombre
          formData.value.direccion = response.direccion === '-' || response.direccion === '' ? '' : response.direccion
          if (response.ubigeo.length === 6) {
            dptoSel.value = `${response.ubigeo.slice(0, 2)}0000`
            await getUbigeos('dpto', '')
            await getUbigeos('prov', response.ubigeo.slice(0, 2))
            provSel.value = `${response.ubigeo.slice(0, 4)}00`
            await getUbigeos('dist', response.ubigeo.slice(0, 4))
            distSel.value = response.ubigeo
          }
          formData.value.foto = ''
          fotoReniec.value = ''
        })
        .catch(error => {
          isBusy.value = false
          emit('error-data', error)
        })
    }

    const saveForm = async () => {
      // Validar datos antes
      if (formData.value.documento === null || formData.value.documento === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar número de documento del Cliente' } } })
        return
      }

      if (formData.value.razonSocial === null || formData.value.razonSocial === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar Razón Social del Cliente' } } })
        return
      }

      if (dptoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar departamento donde reside el Cliente' } } })
        return
      }

      if (provSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar provincia donde reside el Cliente' } } })
        return
      }

      if (distSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar distrito donde reside el Cliente' } } })
        return
      }

      let service = 'catalogo/EMPRESA_CREATE'
      if (props.formType === 'edit') {
        service = 'catalogo/EMPRESA_UPDATE'
      }

      // formData.value.tipoDocumento = tipoDocSel.value
      formData.value.ubigeo = {
        idUbigeo: distSel.value,
      }
      formData.value.ruc = formData.value.documento
      formData.value.logo = (fileLogo.value !== null) ? fileLogo.value : null

      await store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const getLogo = (data, tipo) => {
      if (tipo === 'logo') imgBase64Logo.value = `${pathMultimedia.value}byUrl?isthumb=true&url=${data.url}`
      if (tipo === 'other') imgBase64Logo.value = `${pathMultimedia.value}byUrl?isthumb=true&url=${data.url}`
    }

    const toggleSidebar = async val => {
      emit('update:is-toggle-sidebar', val)
      resetForm()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Empresa'
        await getUbigeos('dpto', '')
        await getUbigeos('prov', dptoSel.value.slice(0, 2))
      }
      if (props.formType === 'edit') {
        formData.value = {
          idEmpresa: props.dataEdit.idEmpresa,
          documento: props.dataEdit.ruc,
          razonSocial: props.dataEdit.razonSocial,
          direccion: props.dataEdit.direccion,
          logo: props.dataEdit.logo,
          estado: props.dataEdit.estado,
        }
        fotoReniec.value = props.dataEdit.foto
        dptoSel.value = `${props.dataEdit.ubigeo.idUbigeo.slice(0, 2)}0000`
        await getUbigeos('dpto', '')
        await getUbigeos('prov', props.dataEdit.ubigeo.idUbigeo.slice(0, 2))
        provSel.value = `${props.dataEdit.ubigeo.idUbigeo.slice(0, 4)}00`
        await getUbigeos('dist', props.dataEdit.ubigeo.idUbigeo.slice(0, 4))
        distSel.value = props.dataEdit.ubigeo.idUbigeo
        titleForm.value = 'Editar Empresa'
        if (props.dataEdit.logo) getLogo({ url: props.dataEdit.logo, isthumb: true }, 'logo')
      }
    }

    return {
      titleForm,
      isBusy,
      formData,
      searchData,
      fotoReniec,
      saveForm,
      toggleSidebar,
      resetForm,
      dptoSel,
      provSel,
      distSel,
      dptos,
      provs,
      dists,
      getUbigeos,
      placeholderTP,
      onDeleteLogo,
      uploadLogo,
      imgBase64Logo,
      refInputElLogo,
      fileLogo,
      previewElLogo,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
